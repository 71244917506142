@import 'src/core/common/assets/styles/mixins';

.menuControl,
.menuClose {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.menuControlIcon {
  fill: var(--typography-100);
  width: 24px;
  height: 24px;
}

.root {
  font-size: 0;
  line-height: 0;
}

.menuContent {
  color: var(--typography-200);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.menuContentWrapper {
  width: 100%;
  max-width: 312px;
  background: var(--gradient-amethyst);
  padding: 20px 15px;

  @include respondTo(l) {
    padding-left: 34px;
    padding-right: 34px;
  }
}

.menuCloseIcon {
  fill: var(--typography-100);
  width: 20px;
  height: 20px;
}

.menuCloseWrapper {
  margin-bottom: 20px;
}

.menuNavList {
  margin-top: -10px;
  margin-bottom: -10px;
}

.menuNavListItem {
  padding-top: 10px;
  padding-bottom: 10px;
}

.menuNavLink {
  font-size: 14px;
  line-height: 20px;
}

.login {
  width: 100%;
}

.loginWrapper {
  margin-top: auto;
}

.menuNav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.logInLink {
  text-align: center;
  padding: 10px 50px;
  font-size: 16px;
  font-weight: 600;
  line-height: 135%;
  color: var(--typography-100);
  border: 1px solid var(--primary);
  border-radius: 30px;
  width: 100%;
  margin-top: auto;
  margin-bottom: 32px;

  @include transition(transform, 150ms, ease-in-out);

  &:active {
    @include scale(1.03);
  }
}

// stylelint-disable selector-class-pattern
.ask-favorite-wrapper {
  $size: 30px;
  $heartColor: var(--error-400);

  display: inline-block;
  height: $size;
  position: relative;
  width: $size;

  input[type="checkbox"] {
    display: none;
    position: absolute;
    top: 0;
    left: 0;

    // Set the base rotation
    & + label svg {
      transform: rotate(0deg);
      width: 100%;
      height: 100%;
    }

    // Wiggle Animation on check

    &:checked + label svg {
      animation: heartWiggle 400ms 50ms forwards ease-in-out;
    }

    // SVG animation on check

    &:checked + label svg #heart-path {
      animation: heartAdd 300ms forwards;
      fill: var(--error-400);
      stroke: var(--error-400);
    }

    // Transition the fill
    & + label svg #heart-path {
      transition: fill 200ms;
    }

    & + label:hover svg #heart-path {
      transition: stroke 150ms linear;
      stroke: var(--error-400);
    }
  }

  .ask-favorite-label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
  }

  @keyframes heartWiggle {
    0% {
      transform: rotate(0deg);
    }

    25% {
      transform: rotate(-10deg);
    }

    75% {
      transform: rotate(8deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes heartAdd {
    0% {
      stroke-width: 5;
    }

    50% {
      stroke-width: 20;
    }

    100% {
      fill: $heartColor;
      stroke-width: 5;
    }
  }
}


@import "src/core/common/assets/styles/mixins";

$headerHeight: 50px;

.content {
  flex-grow: 1;
  display: flex;
  height: calc(100vh - #{$headerHeight});
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include respondTo(l) {
    justify-content: normal;
    padding-top: 50px;
  }
}

.title {
  font-weight: 600;
  font-size: 24px;
  line-height: 33px;
  color: var(--typography-200);
  margin-bottom: 20px;

  @include respondTo(l) {
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 44px;
  }
}

.text {
  color: var(--typography-200);
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 30px;
}

.buttonWrapper {
  margin-bottom: 0;
  margin-top: 0;
}

.button {
  width: 196px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  padding: 5px 10px;
  align-self: center;
  border-radius: 30px;
  outline: none;
  border: none;
  color: var(--typography-100);
  font-size: 16px;
  line-height: 24px;
  height: 40px;
  background: var(--gradient-pink-base);

  @include respondTo(l) {
    width: 380px;
    height: 44px;
  }
}

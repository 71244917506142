@import "src/core/common/assets/styles/mixins";


.container {
  width: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: $maximalContainerWidth;
  margin: 0 auto;
  padding: 0 15px;

  @include respondTo(l) {
    padding: 0 50px
  }
}

.content {
  flex-grow: 1;
  display: flex;
  align-self: center;
  width: 100%;

  & > div {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

.logo {
  width: 141px;
  height: 20px;
  fill: var(--primary-100);
}

.logoDark {
  width: 141px;
  height: 20px;
  fill: var(--primary-900);
}

.logoSmall {
  width: 24px;
  height: 24px;
  fill: var(--primary-100);
}
@import 'src/core/common/assets/styles/mixins';

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  width: 100%;
  position: relative;
  align-self: center;
  padding: 17px 25px 0;

  @include respondTo(l) {
    align-self: flex-start;
    justify-content: normal;
    padding-top: 30px;
  }
}

.menu {
  position: absolute;
  right: 15px;
  top: 18px;
  bottom: 0;
  display: flex;
  align-items: center;

  @include respondTo(l) {
    right: 25px;
    left: initial;
    top: 25px;
  }
}

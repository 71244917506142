@import "reset";
@import "mixins";
@import "themes";
@import "vars.module";
@import "colors.module";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--typography-100);
}

#__next {
  min-height: 100%;
  display: flex;
}

:root {
 @include darkThemeVariables();
}

@include whenThemeIs(light) {
  .themeRoot {
    @include lightThemeVariables()
  }
}

#ot-sdk-btn-floating {
  display: none !important;
}

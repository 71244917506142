@import 'src/core/common/assets/styles/mixins';

.backdrop {
  @include fillContainer(fixed);

  z-index: $appLoaderZIndex;
  display: flex;
  align-items: center;
  justify-content: center;
}

.backdropBlurred {
  backdrop-filter: blur(10px);
}

.backdropFilled {
  background: var(--gradient-amethyst);
}
